const http = require('./http');
const authToken = localStorage.getItem("authToken");

const getCallRecordsData = (payload) => {
    // console.log("payload", payload)
    return new Promise((resolve, reject) => {
        http.get(`/getCallHistoryList?accountId=${payload.accId}&createdfrom=${payload.fromdate}&createdto=${payload.todate}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getExportLogData = (payload) => {
    // console.log("payload", payload)
    return new Promise((resolve, reject) => {
        http.get(`/exportLog?accountId=${payload.accId}&createdfrom=${payload.fromdate}&createdto=${payload.todate}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

module.exports = {
    //getPaginationDataList,
    getCallRecordsData,
    getExportLogData
};