var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.callRecordsData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
var item = ref.item;
return [(item.direction == 'inbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_callback ")])]}}],null,true)},[_c('span',[_vm._v("Inbound")])]):_vm._e(),(item.direction == 'outbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_forwarded ")])]}}],null,true)},[_c('span',[_vm._v("Outbound")])]):_vm._e()]}},{key:"item.caller_id_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.caller_id_number)+" ("+_vm._s(item.caller_id_name)+") ")]}},{key:"item.dialed_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dialed_number)+" "),(item.callee_id_name)?_c('span',[_vm._v(" ("+_vm._s(item.callee_id_name)+") ")]):_vm._e()]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDollarValue")(item.cost))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }