<template>
  <section>
    <v-data-table :headers="headers" :items="callRecordsData" :search="search">
      <template v-slot:[`item.indexNo`]="{ item }">
        <!-- {{ callRecordsData.indexOf(item) + 1 }} -->
        <v-tooltip bottom v-if="item.direction == 'inbound'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" dark v-bind="attrs" v-on="on">
              phone_callback
            </v-icon>
          </template>
          <span>Inbound</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.direction == 'outbound'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange" dark v-bind="attrs" v-on="on">
              phone_forwarded
            </v-icon>
          </template>
          <span>Outbound</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.caller_id_number`]="{ item }">
        {{ item.caller_id_number }} ({{ item.caller_id_name }})
      </template>
      <template v-slot:[`item.dialed_number`]="{ item }">
        {{ item.dialed_number }}
        <span v-if="item.callee_id_name"> ({{ item.callee_id_name }}) </span>
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        {{ item.cost | getDollarValue }}
      </template>
    </v-data-table>
  </section>
</template>


<script>
export default {
  props: ["headers", "callRecordsData", "search"],
  filters: {
    getDollarValue(cost) {
      var getDollarCost = cost / 10000;
      return parseFloat(getDollarCost.toFixed(4));
    },
  },
};
</script>